import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 1250.000000 1000.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)">


<path d="M3441 7569 c-302 -59 -528 -311 -543 -604 l-3 -60 2523 -3 2524 -2
-4 182 c-3 182 -3 183 -35 250 -44 94 -109 160 -202 206 l-75 37 -2070 1
c-1150 1 -2091 -2 -2115 -7z"/>
<path d="M3900 4665 l0 -735 145 0 145 0 2 471 3 470 315 -470 315 -470 133
-1 132 0 0 735 0 735 -145 0 -145 0 -2 -476 -3 -475 -319 475 -320 476 -128 0
-128 0 0 -735z"/>
<path d="M5358 5394 c-5 -4 -8 -335 -8 -736 l0 -728 145 0 145 0 0 225 0 225
133 0 c72 1 167 7 210 15 147 26 292 116 353 220 87 150 94 357 17 515 -36 74
-123 159 -203 199 -123 60 -147 63 -480 68 -168 3 -308 1 -312 -3z m626 -297
c105 -48 150 -163 115 -292 -14 -50 -80 -115 -134 -133 -22 -8 -104 -16 -182
-19 l-143 -6 0 243 0 243 145 -6 c125 -5 153 -9 199 -30z"/>
<path d="M7999 5389 c-290 -42 -540 -276 -605 -566 -25 -112 -16 -282 20 -388
71 -209 222 -369 426 -452 131 -52 134 -53 988 -53 l792 0 0 728 0 729 -337 6
c-448 9 -1214 7 -1284 -4z m28 -520 c43 -14 121 -101 129 -145 20 -105 -19
-201 -99 -248 -88 -52 -187 -43 -259 22 -140 126 -81 345 102 383 37 7 75 4
127 -12z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
